var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vx-row match-height" },
    [
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4" },
        [
          _c(
            "vx-card",
            {
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.openLocationList()
                },
              },
            },
            [
              _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
                _c("img", {
                  staticClass: "responsive card-img-top",
                  attrs: {
                    src: "https://static.travelify.io/tools/locationlists.png",
                  },
                  on: { error: _vm.setAltImgForThumbnail },
                }),
              ]),
              _c("div", { staticClass: "flex flex-wrap justify-start" }, [
                _c("h5", { staticClass: "mt-2" }, [_vm._v("Location Lists")]),
              ]),
              _c("p", { staticClass: "text-grey mt-4" }, [
                _vm._v(
                  "Manage your own custom destination lists for your searchboxes"
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4" },
        [
          _c(
            "vx-card",
            {
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.openAccomodationOverrides()
                },
              },
            },
            [
              _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
                _c("img", {
                  staticClass: "responsive card-img-top",
                  attrs: {
                    src: "https://static.travelify.io/tools/accommodationoverrides.png",
                  },
                  on: { error: _vm.setAltImgForThumbnail },
                }),
              ]),
              _c("div", { staticClass: "flex flex-wrap justify-start" }, [
                _c("h5", { staticClass: "mt-2" }, [
                  _vm._v("Accommodation Overrides"),
                ]),
              ]),
              _c("p", { staticClass: "text-grey mt-4" }, [
                _vm._v(
                  "Enable/disable properties and set priority ordering from your suppliers API results"
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm.appHasFeature("customproperties")
        ? _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4" },
            [
              _c(
                "vx-card",
                {
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.openPropertyContent()
                    },
                  },
                },
                [
                  _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
                    _c("img", {
                      staticClass: "responsive card-img-top",
                      attrs: {
                        src: "https://static.travelify.io/tools/propcontentbuilder.png",
                      },
                      on: { error: _vm.setAltImgForThumbnail },
                    }),
                  ]),
                  _c("div", { staticClass: "flex flex-wrap justify-start" }, [
                    _c("h5", { staticClass: "mt-2" }, [
                      _vm._v("Property Content Loader"),
                    ]),
                  ]),
                  _c("p", { staticClass: "text-grey mt-4" }, [
                    _vm._v(
                      "Upload custom property content for use with the Travelify/Intuitive Contracting Engine"
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.appHasFeature("customextras")
        ? _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4" },
            [
              _c(
                "vx-card",
                {
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.openCustomExtras()
                    },
                  },
                },
                [
                  _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
                    _c("img", {
                      staticClass: "responsive card-img-top",
                      attrs: {
                        src: "https://static.travelify.io/tools/customextras.png",
                      },
                      on: { error: _vm.setAltImgForThumbnail },
                    }),
                  ]),
                  _c("div", { staticClass: "flex flex-wrap justify-start" }, [
                    _c("h5", { staticClass: "mt-2" }, [
                      _vm._v("Custom Extra Upsells"),
                    ]),
                  ]),
                  _c("p", { staticClass: "text-grey mt-4" }, [
                    _vm._v(
                      "Load custom extras to upsell in your travel booking flows"
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4" },
        [
          _c(
            "vx-card",
            {
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.openRssLinkBuilder()
                },
              },
            },
            [
              _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
                _c("img", {
                  staticClass: "responsive card-img-top",
                  attrs: {
                    src: "https://static.travelify.io/tools/rsslinkbuilder.png",
                  },
                  on: { error: _vm.setAltImgForThumbnail },
                }),
              ]),
              _c("div", { staticClass: "flex flex-wrap justify-start" }, [
                _c("h5", { staticClass: "mt-2" }, [_vm._v("RSS Link Builder")]),
              ]),
              _c("p", { staticClass: "text-grey mt-4" }, [
                _vm._v(
                  "Build links to your RSS feed of travel offers for embedding in email newsletters and more"
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm.appHasFeature("rewards")
        ? _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4" },
            [
              _c(
                "vx-card",
                {
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.openRewardsRule()
                    },
                  },
                },
                [
                  _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
                    _c("img", {
                      staticClass: "responsive card-img-top",
                      attrs: {
                        src: "https://static.travelify.io/tools/rewards.png",
                      },
                      on: { error: _vm.setAltImgForThumbnail },
                    }),
                  ]),
                  _c("div", { staticClass: "flex flex-wrap justify-start" }, [
                    _c("h5", { staticClass: "mt-2" }, [
                      _vm._v("Booking Rewards"),
                    ]),
                  ]),
                  _c("p", { staticClass: "text-grey mt-4" }, [
                    _vm._v(
                      "Load custom rewards that your customers can earn on bookings"
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.appHasFeature("memberships")
        ? _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4" },
            [
              _c(
                "vx-card",
                {
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.openTool("application-tools-membership-levels")
                    },
                  },
                },
                [
                  _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
                    _c("img", {
                      staticClass: "responsive card-img-top",
                      attrs: {
                        src: "https://static.travelify.io/tools/memberships.png",
                      },
                      on: { error: _vm.setAltImgForThumbnail },
                    }),
                  ]),
                  _c("div", { staticClass: "flex flex-wrap justify-start" }, [
                    _c("h5", { staticClass: "mt-2" }, [
                      _vm._v("Membership Levels"),
                    ]),
                  ]),
                  _c("p", { staticClass: "text-grey mt-4" }, [
                    _vm._v("Membership Levels"),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.appHasFeature("gsheetseditor")
        ? _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4" },
            [
              _c(
                "vx-card",
                {
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.openTool("application-tools-google-sheets")
                    },
                  },
                },
                [
                  _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
                    _c("img", {
                      staticClass: "responsive card-img-top",
                      attrs: {
                        src: "https://static.travelify.io/tools/gsheetseditor.png",
                      },
                      on: { error: _vm.setAltImgForThumbnail },
                    }),
                  ]),
                  _c("div", { staticClass: "flex flex-wrap justify-start" }, [
                    _c("h5", { staticClass: "mt-2" }, [_vm._v("Data Editor")]),
                  ]),
                  _c("p", { staticClass: "text-grey mt-4" }, [
                    _vm._v(
                      "Access a list of your content editors that allow you to edit dynamic content on your website"
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4" },
        [
          _c(
            "vx-card",
            {
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.openTool("application-tools-gift-vouchers")
                },
              },
            },
            [
              _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
                _c("img", {
                  staticClass: "responsive card-img-top",
                  attrs: {
                    src: "https://static.travelify.io/tools/giftvouchers.png",
                  },
                  on: { error: _vm.setAltImgForThumbnail },
                }),
              ]),
              _c("div", { staticClass: "flex flex-wrap justify-start" }, [
                _c("h5", { staticClass: "mt-2" }, [_vm._v("Gift Vouchers")]),
              ]),
              _c("p", { staticClass: "text-grey mt-4" }, [
                _vm._v("Manage your client gift vouchers and usage."),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm.appHasFeature("skyscanner")
        ? _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4" },
            [
              _c(
                "vx-card",
                {
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.openTool(
                        "application-tools-skyscanner-locations"
                      )
                    },
                  },
                },
                [
                  _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
                    _c("img", {
                      staticClass: "responsive card-img-top",
                      attrs: {
                        src: "https://static.travelify.io/tools/skyscanner.png",
                      },
                      on: { error: _vm.setAltImgForThumbnail },
                    }),
                  ]),
                  _c("div", { staticClass: "flex flex-wrap justify-start" }, [
                    _c("h5", { staticClass: "mt-2" }, [
                      _vm._v("Skyscanner Locations"),
                    ]),
                  ]),
                  _c("p", { staticClass: "text-grey mt-4" }, [
                    _vm._v("Manage Skyscanner hotel search locations"),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.externalTools, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4",
          },
          [
            _c(
              "vx-card",
              {
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.openExternalTool(item.url)
                  },
                },
              },
              [
                _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
                  _c("img", {
                    staticClass: "responsive card-img-top",
                    attrs: { src: item.imageUrl },
                    on: { error: _vm.setAltImgForThumbnail },
                  }),
                ]),
                _c("div", { staticClass: "flex flex-wrap justify-start" }, [
                  _c("h5", { staticClass: "mt-2" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                ]),
                _c("p", { staticClass: "text-grey mt-4" }, [
                  _vm._v(_vm._s(item.description)),
                ]),
              ]
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }